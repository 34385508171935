/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { styled, CircularProgress, Grid } from '@mui/material'
import { useFeatureValue, useFeatureIsOn } from '@growthbook/growthbook-react'
import { GROWTHBOOK_PROMO_FINANCING, GROWTHBOOK_VARIANTS } from '@constants/growthbook-flags'
import { removeUnavailableItems, addToCart, compareLineItems } from '@helpers/cart'
import { getLineItems } from '@helpers/checkout/global'
import { setEquickDecision } from '@helpers/checkout/payment-section/rtg-finance'
import { reloadStoreCart, removeStoreCartItem, toggleStoreCartItemESC } from '@helpers/store-cart'
import { productFinancing } from '@helpers/finance'
import { getRegionZone } from '@helpers/geo-location'
import { addToDataLayer_nextgen } from '@helpers/google-tag-manager'
import { getTotalDisplayValue } from '@helpers/order'
import { availabilityStockMessage } from '@helpers/product'
import { sentryLogger, setExtra, levels } from '@helpers/sentry-logger'
import useGetYMALItems from '@hooks/useGetYMALItems'
import { checkSplitElig } from '@services/checkout'
import CondensedContact from '@shared/condensed-contact-links'
import PromotionContentGroup from '@shared/promotion-content-group'
import { CartAndCheckoutSpinnerWrapper, LoadingMessage } from '@shared/LoadingSpinner/CartAndCheckoutSpinner'
import { PRODUCT_CAROUSEL } from '../../@rtg2022/index'
import { SplitDeliveryText, SplitDeliveryTextWrapper } from './cart-parts/styles'
import CheckoutSticky from '../checkout/checkout-sticky/checkout-sticky'
import CartCreditCardBanner from './cart-parts/CartCreditCardBanner'
import CartEmpty from './cart-parts/cart-empty'
import CartProduct from './cart-parts/cart-product'
import LargeHeader from './cart-parts/large-header'
import PaymentMethods from './cart-parts/payment-methods'
import SalesContact from './cart-parts/sales-contact'
import EquickBanner from './equickBanner'
import StoreCart from './store-cart'
import StoreCartParagraph from './store-cart/store-cart-paragraph'

import '@comp-sass/cart/cart.sass'

const FullWidthDiv = styled('div')({
  width: '100%',
})

const MobileCartCreditCardBannerWrapper = styled('div')({
  width: '100vw',
  marginLeft: '-0.9375rem',
  marginRight: '-0.9375rem',
  marginBottom: 15,
})

const splitCheckVerbiage =
  'Some items may be available sooner than others. You may be able to split this delivery at checkout.'

const AllCartsWrapper = ({
  cart,
  order,
  discount,
  isMobile,
  promoTargetSkus,
  rtg_location,
  setSkusNotAvailable,
  showPayPal,
  skusNotAvailable,
  storeCartIsLoading,
  setStoreCartIsLoading,
}) => {
  const [splitDeliveryElig, setSplitDeliveryElig] = useState(false)
  const [shouldShowFinanceText, setShouldShowFinanceText] = useState(false)
  const [totalFinanceAmount, setTotalFinanceAmount] = useState(0)
  const [financeNumOfMonths, setFinanceNumOfMonths] = useState(0)
  const promoFinancing = useFeatureValue(GROWTHBOOK_PROMO_FINANCING)
  const isShowFinanceCalculatorMessageFeatureOn = useFeatureIsOn(
    GROWTHBOOK_VARIANTS.cart?.[isMobile ? 'mobile' : 'desktop']?.showFinanceCalculator,
  )

  const [showEquickBanner, setShowEquickBanner] = useState(false)
  const [isAccepted, setIsAccepted] = useState(false)
  const { isPrequalified = false, quickScreenInfo = {} } = order?.financePlan ?? {}
  const isQuickScreenPending = quickScreenInfo?.decision === 'CREDIT_PENDING'
  const { region: myRegion } = getRegionZone()

  const productsAvailable = skusNotAvailable.length < 1

  const handleAccept = useCallback(async () => {
    /* This function is called when the user closes the Sychrony modal that opens after clicking the Accept Offer button in equickBanner.
        Handle these values of decision in the API response:
        1) "APPROVED" - user closes modal without completing application (nothing has changed, so continue showing pre-approval offer),
        2) "CREDIT_APPROVED" - user completes app and is approved (change status to approved and display congrats msg & checkout btn),
        3) "CREDIT_PENDING" - user completes app and approval is pending (display pending approval msg and hide CTA btn), or
        4) "CREDIT_DECLINED" - user completes app but is declined credit (clear QS info).
    */
    try {
      setShowEquickBanner(false)
      setStoreCartIsLoading(true)
      await reloadStoreCart(true)
    } catch {
      setEquickDecision()
      console.error('Error updating Store Cart')
    } finally {
      setStoreCartIsLoading(false)
    }
  }, [setStoreCartIsLoading])

  const eQuickProps = useMemo(
    () => ({
      acceptedMessage: isQuickScreenPending
        ? 'Your Rooms To Go credit card application has been submitted and is currently pending approval.'
        : undefined,
      creditLimit:
        quickScreenInfo?.creditLimit || quickScreenInfo?.creditLimit === 0 ? `${quickScreenInfo?.creditLimit}` : '',
      disableCheckout: !productsAvailable,
      expirationDate: quickScreenInfo?.offerExpiryDate ?? '',
      handleAccept,
      isAccepted,
      isPending: isQuickScreenPending,
      sitecode: isMobile ? 'rgpcsl1d1' : 'rgpcse1d1',
      synchronyEapplyUrl: quickScreenInfo?.synchronyEapplyUrl ?? '',
    }),
    [handleAccept, isAccepted, isMobile, quickScreenInfo],
  )

  const storeCartImages = {}
  if (order?.storeCart?.lineItems) {
    order?.storeCart?.lineItems?.forEach(item => {
      storeCartImages[item.sku] = item?.imageUrl ?? null
    })
  }

  const storeCartLineItems = useMemo(
    () => (order && order.lineItems ? order.lineItems.filter(lineItem => lineItem.isStoreSku) : []),
    [order.lineItems],
  )
  const storeCartHasProducts = storeCartLineItems.length > 0
  const onlineCartHasProducts = cart && cart?.cartItems && cart?.cartItems?.length > 0

  const storeCartWarrantyItems = !storeCartLineItems
    ? []
    : order?.storeCart?.lineItems.map(item => ({
        sku: item.sku,
        showWarranty: item.warrantyEnabled,
        warrantyPrice: item.warrantyEnabled ? item.warrantyPrice.price : null,
        warrantyProvider: item.warrantyEnabled ? item.warrantyPrice?.provider_name ?? '' : null,
      }))

  const ymalSkuList = useMemo(
    () =>
      order?.lineItems
        ?.filter(item => item?.deliveryType !== 'T')
        ?.map(item => {
          if (item?.isConfigurable) {
            const childSkus = item?.childItems.map(i => i.sku).join(',') ?? ''
            return childSkus
          }
          return item?.sku
        })
        ?.join(','),
    [order],
  )

  const { data: ymalCarouselSlides } = useGetYMALItems({}, ymalSkuList)

  const lineItems = getLineItems()
  const cartQuantityHasChanged = !compareLineItems(lineItems, order?.lineItems ?? [])
  // Prevent store cart loading from appearing when cart only has online products
  const showStoreCartLoading = storeCartIsLoading && !cartQuantityHasChanged

  useEffect(() => {
    setShowEquickBanner(isPrequalified)
    if (quickScreenInfo?.decision === 'CREDIT_APPROVED' || isQuickScreenPending) setIsAccepted(true)
  }, [isPrequalified, quickScreenInfo])

  useEffect(() => {
    let showFinanceText = false

    const total = getTotalDisplayValue(order, false, {}, cart, storeCartLineItems)
    const { showFinance, financeAmount, financeNumMonths } = productFinancing(
      total,
      null,
      true,
      false,
      0,
      promoFinancing,
    )
    if (showFinance) showFinanceText = true
    setTotalFinanceAmount(financeAmount)
    setFinanceNumOfMonths(financeNumMonths)
    setShouldShowFinanceText(showFinanceText)
  }, [JSON.stringify(cart.cartItems), order.total, promoFinancing])

  useEffect(() => {
    let fetching = false

    async function fetchSplitCheckElig() {
      const result = await checkSplitElig(order.orderId, rtg_location.zip)
      if (!fetching) setSplitDeliveryElig(result)
    }
    try {
      if (order.orderId && rtg_location.zip) {
        fetchSplitCheckElig()
      }
    } catch (error) {
      sentryLogger({
        configureScope: {
          type: setExtra,
          message: `cart - fetching split check eligibility: ${error}`,
          level: levels.error,
        },
      })
    }
    return () => {
      fetching = true
    }
  }, [JSON.stringify(order.lineItems), order.orderId, rtg_location.zip])

  const removeStoreCartProduct = async storeCartItemToRemove => {
    if (storeCartHasProducts) {
      removeStoreCartItem(order.lineItems, order.orderId, setSkusNotAvailable, storeCartItemToRemove)
    }
  }

  const toggleStoreCartProductWarranty = async sku => {
    if (storeCartHasProducts) {
      toggleStoreCartItemESC(order.lineItems, order.orderId, sku)
    }
  }

  const renderCartProducts = () =>
    cart.cartItems.map((item, index) => {
      // adds stock message to any child products of a cart product
      const items_in_room =
        item?.product?.items_in_room?.map?.(itm => ({
          ...itm,
          stockMessage: availabilityStockMessage({
            availableOn: false,
            product: item.product,
            rtg_location,
          }),
        })) || []

      const cartItem = { ...item, product: { ...item.product, stockMessage: '', items_in_room } }
      //  Check if order.promotions.lineItems contains a matching sku with bonusItemSavings property and discount is applied?
      const promoInfo =
        order?.promotions?.lineItems?.find(
          promo => promo.sku === cartItem.sku && promo.bonusItemSavings && promo.promotionStatus === 'APPLIED',
        ) || null

      const additionalOptionLineItem = cartItem.additionalOption
        ? order?.lineItems?.find(lineItem => lineItem.sku === cartItem?.sku && !!lineItem.additionalOption)
        : null

      const couponApplied =
        (!!cartItem.product?.couponDisplayData || !!cartItem.product?.couponDisplayFields?.[`${myRegion}`]) &&
        (cart.cartItems.some(_cartItem => _cartItem?.promotion?.promotionItemSku === cartItem.sku) ||
          !cartItem.product.promotions)

      return (
        <div key={cartItem?.uid || cartItem?.sku} className="online-cart-wrapper">
          {index === 0 && <LargeHeader isMobile={isMobile} text="Online Cart" />}
          {index === 0 && splitDeliveryElig && skusNotAvailable?.length === 0 && (
            <SplitDeliveryTextWrapper mb={2} mt={{ xs: 2, md: 0 }}>
              <SplitDeliveryText>{splitCheckVerbiage}</SplitDeliveryText>
            </SplitDeliveryTextWrapper>
          )}
          <CartProduct
            bonusBuyDiscount={promoInfo?.bonusItemSavings}
            cartItem={cartItem}
            couponApplied={couponApplied}
            index={index}
            isMobile={isMobile}
            productCount={cart?.cartItems?.length}
            region={rtg_location.region}
            unavailableItem={skusNotAvailable?.filter?.(prod => prod.sku === cartItem.sku)?.[0]}
            warrantyEnabled={cartItem.warrantyEnabled || cartItem.isWarrantyEnabled}
            lineItem={additionalOptionLineItem}
            showFinanceMessage={isShowFinanceCalculatorMessageFeatureOn}
          />
        </div>
      )
    })

  const unavailableSkus = skusNotAvailable?.map(i => i.sku) ?? []
  const onlineCartSkus = onlineCartHasProducts ? cart.cartItems.map(i => i.sku) : []
  const showRemovalAll = onlineCartSkus.some(s => unavailableSkus.includes(s))

  return (
    <>
      {showEquickBanner && <EquickBanner handleClose={() => setShowEquickBanner(false)} {...eQuickProps} />}

      <div className="cart-page">
        <div className={classNames('grid-x', { 'grid-margin-x': isMobile })}>
          <div
            className={classNames('cart-product-list-container small-12 medium-12 large-9 grid-x grid-padding-y', {
              cell: isMobile,
            })}
          >
            {/* Both Carts Are Empty Component */}
            {!storeCartHasProducts && !onlineCartHasProducts && !storeCartIsLoading && <CartEmpty />}
            {/* Store Cart Is Loading */}
            {showStoreCartLoading && (
              <CartAndCheckoutSpinnerWrapper>
                <CircularProgress />
                <LoadingMessage>Loading Your Cart</LoadingMessage>
              </CartAndCheckoutSpinnerWrapper>
            )}
            {/* Store Cart Component */}
            {/* Redundant storecartloading check since storeCartHasProducts memo will not run again if the cart has not changed
            but storeCartIsLoading is modified by cartUpdate, which always will */}
            {!storeCartIsLoading && storeCartHasProducts && (
              <StoreCart
                isMobile={isMobile}
                onlineCartHasProducts={onlineCartHasProducts}
                removeStoreCartProduct={removeStoreCartProduct}
                setStoreCartIsLoading={setStoreCartIsLoading}
                splitDeliveryMessage={splitDeliveryElig && skusNotAvailable?.length === 0 ? splitCheckVerbiage : null}
                storeCartImages={storeCartImages}
                storeCartItems={storeCartLineItems}
                storeCartWarrantyItems={storeCartWarrantyItems}
                storeInfo={order?.storeInfo}
                toggleStoreCartProductWarranty={toggleStoreCartProductWarranty}
                unavailableItems={skusNotAvailable?.length ? skusNotAvailable.map(x => x.sku) : []}
              />
            )}

            {/* Online Cart Component */}
            {onlineCartHasProducts && renderCartProducts()}

            {/* Promotions Component */}
            {(onlineCartHasProducts || storeCartHasProducts) && (
              <>
                <PromotionContentGroup targetSkus={promoTargetSkus} isCart />
                <div
                  className="cart-bottom-checkout cell small-12 grid-x"
                  style={
                    isMobile ? null : { marginBottom: !storeCartHasProducts || onlineCartHasProducts ? '15px' : 0 }
                  }
                >
                  {isMobile ? (
                    <PaymentMethods
                      cart={cart}
                      showWellsFargoLogo={order?.altFinancing ?? false}
                      uniqueNameForId="mobilePayMethods"
                    />
                  ) : (
                    <Grid container>
                      <Grid item xs={8}>
                        <PaymentMethods
                          cart={cart}
                          showWellsFargoLogo={order?.altFinancing ?? false}
                          uniqueNameForId="desktopPayMethods"
                          width="90%"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CheckoutSticky
                          cart={cart}
                          discount={discount}
                          financeAmount={totalFinanceAmount}
                          financeNumOfMonths={financeNumOfMonths}
                          onlyButtons
                          order={order}
                          productsAvailable={productsAvailable}
                          removeUnavailableItems={
                            showRemovalAll ? () => removeUnavailableItems(setSkusNotAvailable, skusNotAvailable) : null
                          }
                          showPayPal={showPayPal}
                          storeCartIsLoading={storeCartIsLoading}
                          storeCartLineItems={storeCartLineItems}
                        />
                      </Grid>
                    </Grid>
                  )}
                </div>
              </>
            )}
            {!isMobile && storeCartHasProducts && !onlineCartHasProducts && (
              <StoreCartParagraph isMobile={isMobile} storeName={order?.storeInfo?.storeName} />
            )}

            {/* Credit Card Banner Component (for mobile only; displays in CheckoutSticky sidebar for desktop */}
            {isMobile && (onlineCartHasProducts || storeCartHasProducts) && (
              <>
                {isPrequalified && <EquickBanner isSidebar {...eQuickProps} />}
                <MobileCartCreditCardBannerWrapper>
                  {!isPrequalified && <CartCreditCardBanner isMobile={isMobile} />}
                  <SalesContact />
                </MobileCartCreditCardBannerWrapper>
              </>
            )}

            {/* You May Also Like */}
            {ymalCarouselSlides?.length > 0 && (
              <PRODUCT_CAROUSEL
                id="you-may-also-like__cart"
                title="You May Also Like"
                trigger_gtm={addToDataLayer_nextgen}
                price_zone={`${rtg_location.region}_${rtg_location.price_zone}`}
                slides={ymalCarouselSlides}
                addToCart={addToCart}
                cartItems={cart?.cartItems?.map(product => ({
                  sku: product.sku,
                  price: product.price,
                  quantity: product.quantity,
                }))}
                cartTotal={order.total}
              />
            )}

            {/* {shouldShowFinanceText && isShowFinanceMessageFeatureOn && (
              <p className="financing-disclaimer cell small-12">*when paying with a Rooms To Go credit card</p>
            )} */}

            {/*  Added to stop from passing classes to other divs */}
            <FullWidthDiv>
              <FullWidthDiv>
                <CondensedContact />
              </FullWidthDiv>
            </FullWidthDiv>
          </div>

          {/* Your Order Checkout Component */}
          <div className="checkout-container cell large-2 grid-margin-y">
            {(onlineCartHasProducts || storeCartHasProducts) && (
              <CheckoutSticky
                cart={cart}
                discount={discount}
                eQuickProps={{ isPrequalified, ...eQuickProps }}
                financeAmount={totalFinanceAmount}
                financeNumOfMonths={financeNumOfMonths}
                isMobile={isMobile}
                order={order}
                productsAvailable={productsAvailable}
                removeUnavailableItems={
                  showRemovalAll ? () => removeUnavailableItems(setSkusNotAvailable, skusNotAvailable) : null
                }
                rightSticky
                showPayPal={showPayPal}
                storeCartIsLoading={storeCartIsLoading}
                storeCartLineItems={storeCartLineItems}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

AllCartsWrapper.propTypes = {
  cart: PropTypes.object,
  discount: PropTypes.number,
  isMobile: PropTypes.bool,
  order: PropTypes.object,
  promoTargetSkus: PropTypes.array,
  rtg_location: PropTypes.object,
  setSkusNotAvailable: PropTypes.func,
  showPayPal: PropTypes.bool,
  skusNotAvailable: PropTypes.array,
  storeCartIsLoading: PropTypes.bool,
  setStoreCartIsLoading: PropTypes.func,
}

export default AllCartsWrapper
